import { useMemo } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import {
  getBep20Contract,
  getCakeContract,
  getBunnyFactoryContract,
  getBunnySpecialContract,
  getPancakeRabbitContract,
  getProfileContract,
  getIfoV1Contract,
  getIfoV2Contract,
  getMasterchefContract,
  getBuyHandwithHodlxContract,
  getPointCenterIfoContract,
  getSouschefContract,
  getClaimRefundContract,
  getTradingCompetitionContract,
  getTradingCompetitionContractV2,
  getEasterNftContract,
  getErc721Contract,
  getCakeVaultContract,
  getIfoPoolContract,
  getPredictionsContract,
  getChainlinkOracleContract,
  getLotteryV2Contract,
  getBunnySpecialCakeVaultContract,
  getBunnySpecialPredictionContract,
  getFarmAuctionContract,
  getBunnySpecialLotteryContract,
  getAnniversaryAchievementContract,
  getNftMarketContract,
  getNftSaleContract,
  getPancakeSquadContract,
  getErc721CollectionContract,
  getBunnySpecialXmasContract,
  getGemFighterContract,
  getBuyNFTwithHodlContract,
  getStakeNFTFixedAprContract,
} from 'utils/contractHelpers'
import { getMulticallAddress } from 'utils/addressHelpers'
import { VaultKey } from 'state/types'
import {
  CakeVault,
  EnsPublicResolver,
  EnsRegistrar,
  Erc20,
  Erc20Bytes32,
  IfoPool,
  Multicall,
  Weth,
  Vault,
  ACPHodl,
} from 'config/abi/types'

// Imports below migrated from Exchange useContract.ts
import { Contract } from '@ethersproject/contracts'
import { ChainId, WETH } from '@pancakeswap/sdk'
import { useEthersSigner } from 'utils/ethers'
import IPancakePairABI from '../config/abi/IPancakePair.json'
import ENS_PUBLIC_RESOLVER_ABI from '../config/abi/ens-public-resolver.json'
import ENS_ABI from '../config/abi/ens-registrar.json'
import { ERC20_BYTES32_ABI } from '../config/abi/erc20'
import ERC20_ABI from '../config/abi/erc20.json'
import VAULT_ABI from '../config/abi/vault.json'
import VAULT_ABI_HODL from '../config/abi/vaultForHodl.json'
import ACPHODL_ABI from '../config/abi/ACPHodl.json'
import WETH_ABI from '../config/abi/weth.json'
import multiCallAbi from '../config/abi/Multicall.json'
import { getContract, getProviderOrSigner } from '../utils'

import { IPancakePair } from '../config/abi/types/IPancakePair'

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useIfoV1Contract = (address: string) => {
  const signer = useEthersSigner()
  return useMemo(() => getIfoV1Contract(address, signer), [address, signer])
}

export const useIfoV2Contract = (address: string) => {
  const signer = useEthersSigner()
  return useMemo(() => getIfoV2Contract(address, signer), [address, signer])
}

export const useERC20 = (address: string, withSignerIfPossible = true) => {
  const signer = useEthersSigner()
  return useMemo(
    () => getBep20Contract(address, withSignerIfPossible ? signer : null),
    [address, signer, withSignerIfPossible],
  )
}

/**
 * @see https://docs.openzeppelin.com/contracts/3.x/api/token/erc721
 */
export const useERC721 = (address: string) => {
  const signer = useEthersSigner()
  return useMemo(() => getErc721Contract(address, signer), [address, signer])
}

export const useCake = (withSignerIfPossible = true) => {
  const signer = useEthersSigner()
  return useMemo(() => getCakeContract(withSignerIfPossible ? signer : null), [signer, withSignerIfPossible])
}

export const useBunnyFactory = () => {
  const signer = useEthersSigner()
  return useMemo(() => getBunnyFactoryContract(signer), [signer])
}

export const usePancakeRabbits = () => {
  const signer = useEthersSigner()
  return useMemo(() => getPancakeRabbitContract(signer), [signer])
}

export const useProfileContract = (withSignerIfPossible = true) => {
  const signer = useEthersSigner()
  return useMemo(() => getProfileContract(withSignerIfPossible ? signer : null), [withSignerIfPossible, signer])
}

export const useLotteryV2Contract = () => {
  const signer = useEthersSigner()
  return useMemo(() => getLotteryV2Contract(signer), [signer])
}

export const useMasterchef = () => {
  const signer = useEthersSigner()
  return useMemo(() => getMasterchefContract(signer), [signer])
}

export const useBuyHandwithHodlx = () => {
  const signer = useEthersSigner()
  return useMemo(() => getBuyHandwithHodlxContract(signer), [signer])
}

export const useBuyNFTwithHodl = () => {
  const signer = useEthersSigner()
  return useMemo(() => getBuyNFTwithHodlContract(signer), [signer])
}

export const useBuyGemFighter = (address) => {
  const signer = useEthersSigner()
  return useMemo(() => getGemFighterContract(address, signer), [address, signer])
}

export const useStakeNFTFixedApr = (address) => {
  const signer = useEthersSigner()
  return useMemo(() => getStakeNFTFixedAprContract(address, signer), [address, signer])
}

export const useSousChef = (id) => {
  const signer = useEthersSigner()
  return useMemo(() => getSouschefContract(id, signer), [id, signer])
}

export const usePointCenterIfoContract = () => {
  const signer = useEthersSigner()
  return useMemo(() => getPointCenterIfoContract(signer), [signer])
}

export const useBunnySpecialContract = () => {
  const signer = useEthersSigner()
  return useMemo(() => getBunnySpecialContract(signer), [signer])
}

export const useClaimRefundContract = () => {
  const signer = useEthersSigner()
  return useMemo(() => getClaimRefundContract(signer), [signer])
}

export const useTradingCompetitionContract = () => {
  const signer = useEthersSigner()
  return useMemo(() => getTradingCompetitionContract(signer), [signer])
}

export const useTradingCompetitionContractV2 = (withSignerIfPossible = true) => {
  const signer = useEthersSigner()
  return useMemo(
    () => getTradingCompetitionContractV2(withSignerIfPossible ? signer : null),
    [signer, withSignerIfPossible],
  )
}

export const useEasterNftContract = () => {
  const signer = useEthersSigner()
  return useMemo(() => getEasterNftContract(signer), [signer])
}

export const useVaultPoolContract = (vaultKey: VaultKey): CakeVault | IfoPool => {
  const signer = useEthersSigner()
  return useMemo(() => {
    return vaultKey === VaultKey.CakeVault ? getCakeVaultContract(signer) : getIfoPoolContract(signer)
  }, [signer, vaultKey])
}

export const useCakeVaultContract = () => {
  const signer = useEthersSigner()
  return useMemo(() => getCakeVaultContract(signer), [signer])
}

export const useIfoPoolContract = () => {
  const signer = useEthersSigner()
  return useMemo(() => getIfoPoolContract(signer), [signer])
}

export const usePredictionsContract = () => {
  const signer = useEthersSigner()
  return useMemo(() => getPredictionsContract(signer), [signer])
}

export const useChainlinkOracleContract = (withSignerIfPossible = true) => {
  const signer = useEthersSigner()
  return useMemo(() => getChainlinkOracleContract(withSignerIfPossible ? signer : null), [signer, withSignerIfPossible])
}

export const useSpecialBunnyCakeVaultContract = () => {
  const signer = useEthersSigner()
  return useMemo(() => getBunnySpecialCakeVaultContract(signer), [signer])
}

export const useSpecialBunnyPredictionContract = () => {
  const signer = useEthersSigner()
  return useMemo(() => getBunnySpecialPredictionContract(signer), [signer])
}

export const useBunnySpecialLotteryContract = () => {
  const signer = useEthersSigner()
  return useMemo(() => getBunnySpecialLotteryContract(signer), [signer])
}

export const useBunnySpecialXmasContract = () => {
  const signer = useEthersSigner()
  return useMemo(() => getBunnySpecialXmasContract(signer), [signer])
}

export const useAnniversaryAchievementContract = () => {
  const signer = useEthersSigner()
  return useMemo(() => getAnniversaryAchievementContract(signer), [signer])
}

export const useNftSaleContract = () => {
  const signer = useEthersSigner()
  return useMemo(() => getNftSaleContract(signer), [signer])
}

export const usePancakeSquadContract = () => {
  const signer = useEthersSigner()
  return useMemo(() => getPancakeSquadContract(signer), [signer])
}

export const useFarmAuctionContract = (withSignerIfPossible = true) => {
  const signer = useEthersSigner()
  return useMemo(() => getFarmAuctionContract(withSignerIfPossible ? signer : null), [signer, withSignerIfPossible])
}

export const useNftMarketContract = () => {
  const signer = useEthersSigner()
  return useMemo(() => getNftMarketContract(signer), [signer])
}

export const useErc721CollectionContract = (collectionAddress: string, withSignerIfPossible = true) => {
  const signer = useEthersSigner()
  return useMemo(() => {
    return getErc721CollectionContract(withSignerIfPossible ? signer : null, collectionAddress)
  }, [signer, collectionAddress, withSignerIfPossible])
}

// Code below migrated from Exchange useContract.ts

// returns null on errors
function useContract<T extends Contract = Contract>(
  address: string | undefined,
  ABI: any,
  withSignerIfPossible = true,
): T | null {
  const signer = useEthersSigner()

  return useMemo(() => {
    if (!address || !ABI || !signer) return null
    try {
      return getContract(address, ABI, withSignerIfPossible ? signer : null)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, signer, withSignerIfPossible]) as T
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean) {
  return useContract<Erc20>(tokenAddress, ERC20_ABI, withSignerIfPossible)
}
// Leyr 20220929
export function useVaultContract(contracAddress?: string, withSignerIfPossible?: boolean) {
  return useContract<Vault>(contracAddress, VAULT_ABI, withSignerIfPossible)
}

export function useVaultContractForHodl(contracAddress?: string, withSignerIfPossible?: boolean) {
  return useContract<Vault>(contracAddress, VAULT_ABI_HODL, withSignerIfPossible)
}

export function useACPContract(contracAddress?: string, withSignerIfPossible?: boolean) {
  return useContract<ACPHodl>(contracAddress, ACPHODL_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()

  return useContract<Weth>(
    chainId && chainId === 56 ? WETH[chainId].address : undefined,
    WETH_ABI,
    withSignerIfPossible,
  )
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    // eslint-disable-next-line default-case
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.TESTNET:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
        break
    }
  }
  return useContract<EnsRegistrar>(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract<EnsPublicResolver>(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract<Erc20Bytes32>(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): IPancakePair | null {
  return useContract(pairAddress, IPancakePairABI, withSignerIfPossible)
}

export function useMulticallContract() {
  return useContract<Multicall>(getMulticallAddress(), multiCallAbi, false)
}
